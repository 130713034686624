body {
    margin: 0;
    padding: 0;
}

/* Animations */

.pulsing-animation-1 {
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulsing-animation-1 {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.pulsing-animation-2 {
    transform: scale(1);
    animation: pulse 2s infinite;
    animation-delay: 1s;
}

@keyframes pulsing-animation-2 {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}